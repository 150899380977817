import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import mediaqueries from './../styles/media';
import ItsJuice from './icons/ItsJuice';

const LogoWrapper = () => (
  <StyledLogoWrapper>
    <LogoLink to="/">
      <ItsJuice />
      <p>HANDBOOK</p>
    </LogoLink>
  </StyledLogoWrapper>
);

const StyledLogoWrapper = styled.div`
  p {
    margin: 0;
    font-size: 1.6rem;

    ${mediaqueries.tablet`
      font-size: 0.9rem;
    `};
  }
`;

const LogoLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${p => p.theme.colors.text};
  transition: color ${p => p.theme.transition};
  &:hover,
  &:focus {
    color: ${p => p.theme.colors.primary};
  }
  &:hover svg,
  &:focus svg {
    fill: ${p => p.theme.colors.primary};
  }
  ${mediaqueries.tablet`
    width: 80px;
  `};
`;


export default LogoWrapper;
